import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import StandardBanner from "../../components/StandardHero/StandardHero";
import SubBanner from "../../components/SubBanner/SubBanner";
import Accordion from "../../components/Accordion/Accordion";
import { StandardFilter } from "../../components/StandardFilter";
import parse from "html-react-parser";
const styles = require("./index.module.scss");

const Faqs = ({ data: { strapiFaqPage, allStrapiFaqs } }) => {
  const hero = {
    image: strapiFaqPage.hero.image.localFile.childImageSharp.gatsbyImageData,
    showBreadcrumb: strapiFaqPage.hero.showBreadcrumb,
    title: "",
    breadcrumbs: [{ name: "FAQS" }],
  };

  const subBanner = {
    image: strapiFaqPage.subBanner.image.localFile.publicURL,
    heading: strapiFaqPage.subBanner.heading,
  };

  const faqs = allStrapiFaqs.nodes.map((item) => ({
    question: item?.question,
    answer: item?.answer,
    id: item.id,
    category: item.faq_category.category,
  }));

  const [filteredCatgeories, setFilteredCategories] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState("all");

  const categories = [...new Set(faqs.map((item) => item.category))];

  useEffect(() => {
    const hashtag = window.location.hash;
    if (hashtag) {
      const element = document.querySelector(hashtag);
      element?.scrollIntoView({
        behavior: "smooth",
      });
    }
  });

  useEffect(() => {
    setFilteredCategories([...new Set(faqs.map((item) => item.category))]);
  }, []);

  const handleButtonClick = (category) => {
    category === "all"
      ? setFilteredCategories(categories)
      : setFilteredCategories(categories.filter((item) => item === category));

    setSelectedCategory(category);
  };

  return (
    <Layout
      seo={{
        title: strapiFaqPage.metaData?.pageTitle,
        description: strapiFaqPage.metaData?.metadescription,
        image: strapiFaqPage.hero.image.localFile.publicURL,
      }}
    >
      <StandardBanner
        image={hero.image}
        title={hero.title}
        showBreadcrumb={hero.showBreadcrumb}
        breadcrumbs={hero.breadcrumbs}
      />
      <SubBanner image={subBanner.image} heading={subBanner.heading} />

      <StandardFilter
        selectedCategory={selectedCategory}
        categories={categories}
        buttonClick={handleButtonClick}
        allName="All categories"
      />
      {filteredCatgeories.map((category) => {
        return (
          <>
            <h2 className={styles.catHeader} id={category}>
              {category}
            </h2>
            <Accordion
              transitionTime={300}
              triggerClassName={styles.headerClosed}
              triggerOpenedClassName={styles.headerOpen}
              classParentString={styles.accordionSection}
              triggerOpenedClassName={styles.headerOpen}
              triggerTagName="div"
              openedClassName={styles.accordionOpen}
              closeable={true}
              startPosition={1000}
              careersPage={true}
            >
              {faqs
                ?.filter((item) => item?.category === category)
                .map((faq) => {
                  const header = (
                    <div className={styles.accordionHeader}>
                      <div>
                        <h4> {faq.question} </h4>
                      </div>
                    </div>
                  );
                  return (
                    <div data-trigger={header}>
                      <div className={styles.accordionContent}>
                        <div className={styles.copyWrap}>
                          {parse(faq.answer)}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Accordion>
          </>
        );
      })}
    </Layout>
  );
};

export const query = graphql`
  {
    strapiFaqPage {
      hero {
        showBreadcrumb
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
      }
      subBanner {
        heading
        image {
          localFile {
            publicURL
          }
        }
      }
      metaData {
        pageTitle
        metadescription
      }
    }

    allStrapiFaqs {
      nodes {
        id
        question
        answer
        faq_category {
          category
        }
      }
    }

    allStrapiFaqCategory {
      nodes {
        category
      }
    }
  }
`;

export default Faqs;
