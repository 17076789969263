import React from "react";
import { FilterButton } from "../../components/FilterButton";
const styles = require("./index.module.scss");

export const StandardFilter = (props) => {
  const { allName, buttonClick, categories, selectedCategory } = props;

  return (
    <div className={styles.filter}>
      <ul>
        <li>
          <FilterButton
            selected={selectedCategory === "all" ? true : false}
            click={() => buttonClick("all")}
          >
            {allName}
          </FilterButton>
        </li>
        {categories?.map((category) => (
          <li>
            <FilterButton
              selected={selectedCategory === category ? true : false}
              click={() => buttonClick(category)}
            >
              {category}
            </FilterButton>
          </li>
        ))}
      </ul>
    </div>
  );
};
