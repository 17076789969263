import React from "react";
const styles = require("./index.module.scss");

export const FilterButton = (props) => {
  const { children, click, selected } = props;

  return (
    <button
      onClick={() => click()}
      className={`${styles.filterButton} ${
        selected ? styles.selected : styles.unSelected
      }`}
    >
      {children}
    </button>
  );
};
