// extracted by mini-css-extract-plugin
export var accordion = "index-module--accordion--8V8Rc";
export var accordionContent = "index-module--accordionContent--y9vmy";
export var accordionHeader = "index-module--accordionHeader--2pOLx";
export var accordionSection = "index-module--accordionSection--ciJ3P";
export var catHeader = "index-module--catHeader--Hwokd";
export var copy = "index-module--copy--G6GcO";
export var copyWrap = "index-module--copyWrap--CNizc";
export var header = "index-module--header--u6KxK";
export var headerClosed = "index-module--headerClosed--mHHNo";
export var headerOpen = "index-module--headerOpen--gjNu1";
export var heading = "index-module--heading--v6Fdh";
export var page404Title = "index-module--page404-title--pL-rb";